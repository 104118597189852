import { useCallback, useRef, useEffect } from 'react';

interface UseTrapFocusReturn {
  ref: React.RefObject<HTMLElement>;
  onClose: () => void;
}

export const useTrapFocus = (opts = { focusDelay: 0 }): UseTrapFocusReturn => {
  const containerRef = useRef<HTMLElement>(null);
  const previousFocusRef = useRef<HTMLElement | null>(null);
  const getFocusableElements = useCallback(() => {
    if (!containerRef.current) {
      return [];
    }

    return Array.from(
      containerRef.current.querySelectorAll<HTMLElement>(
        'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
      )
    );
  }, []);

  const handleFocus = useCallback(
    (event: KeyboardEvent) => {
      const focusableElements = getFocusableElements();
      const firstFocusableElement = focusableElements[0];
      const lastFocusableElement =
        focusableElements[focusableElements.length - 1];

      if (event.key === 'Tab') {
        if (
          event.shiftKey &&
          document.activeElement === firstFocusableElement
        ) {
          event.preventDefault();
          lastFocusableElement?.focus();
        } else if (
          !event.shiftKey &&
          document.activeElement === lastFocusableElement
        ) {
          event.preventDefault();
          firstFocusableElement?.focus();
        }
      }
    },
    [getFocusableElements]
  );

  useEffect(() => {
    const currentContainer = containerRef.current;

    if (currentContainer) {
      previousFocusRef.current = document.activeElement as HTMLElement;
      const focusableElements = getFocusableElements();

      if (focusableElements.length > 0) {
        setTimeout(() => {
          focusableElements[0]?.focus();
        }, opts.focusDelay);
      }

      window.addEventListener('keydown', handleFocus, true);
    }

    return () => {
      window.removeEventListener('keydown', handleFocus, true);

      if (previousFocusRef.current) {
        previousFocusRef.current.focus();
      }
    };
  }, [getFocusableElements, handleFocus, opts.focusDelay]);

  const onClose = useCallback(() => {
    if (previousFocusRef.current) {
      previousFocusRef.current.focus();
    }
  }, []);

  return { ref: containerRef, onClose };
};
